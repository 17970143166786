import React from 'react';

const LeandingPage = () => {
    return (
        <>
            <div>
                <img
                    src={'/images/bg.webp'}
                    alt="Coming Soon"
                    className="w-full h-screen fixed -z-10"
                />
                <h1 className="from-neutral-100 mt-20 py-[100px] font-black leading-relaxed text-7xl text-center text-orange-500">
                    Coming Soon
                </h1>
                <img
                    className="mx-auto"
                    src={'/images/neuron_icon.png'}
                    alt="logo"
                />
            </div>
        </>
    );
};

export default LeandingPage;
