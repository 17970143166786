
import './App.css';
import Header from './Components/header';
import LeandingPage from './Components/leandingPage';

function App() {
  return (
    <>
    <Header/>
    <LeandingPage/>
    </>
  );
}

export default App;
