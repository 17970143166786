const Header = () => {
    return (
        <header className="w-full fixed top-0 bg-white bg-opacity-10 backdrop-blur-xl z-50 px-8 lg:px-0">
            {/* Main Header */}
            <div className="flex  w-full py-6 justify-between  h-[84px] items-center mx-auto lg:max-w-[1280px] ">
                <div className="flex gap-2 w-fit items-center">
                    <img
                        src={'/images/neuron_name_logo.png'}
                        alt=""
                        className="w-auto h-[30px]"
                    />
                </div>

                <div className="md:flex hidden space-x-2">
                    <button
                        className="bg-orange-500 text-white px-6 py-2 rounded-full font-medium text-sm"
                        style={{ fontFamily: 'Arial, sans-serif' }}
                    >
                        <a href="#">Sign Up</a>
                    </button>
                    <button
                        className="bg-white border-2 border-orange-500 text-orange-500 px-6 py-2 rounded-full font-medium text-sm"
                        style={{ fontFamily: 'Arial, sans-serif' }}
                    >
                        <a href="#">Login</a>
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
